

@media (max-width: 768px) {
.container-section1{
    display: flex;
    flex-direction:column-reverse;
    justify-content: center;
    height: 60vh;
}

.consultorioImg-section1{
    width: 100%;
    height: 50%;
    border-radius: 10px;
}

.texto-section1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #6aa9e9;
    width: 100%;
    height: 50%;
}

.texto-section1 h2{
    margin: 10px 0;
    font-size: 28px;
}

.section2{
    display: flex;
    flex-direction:column;
    justify-content: center;
    text-align: center;
    background-color: #6aa9e9;
    border-radius: 10px;
    color: white;
}

.section2 h2{
    margin-top: 30px;
    font-size: 25px;
}

.foto-perfil{
    width: 50%;
    height: 30%;
    border-radius: 15px;
}

.about-me p{
    text-align:center;
    margin: 15px 30px;
}

.experienciaProfissional ul{
    text-align: justify;
    list-style: none;
    padding: 0;
    margin: 20px 30px;
}

.experienciaProfissional li {
    margin-top: 10px;
}

.container-aboutTo{
    display: flex;
    flex-direction:column;
    justify-content: center;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    color: #6aa9e9;
    margin-top: 10px;
}

.foto-puzzle{
    width: 50%;
    height: 40%;
    border-radius: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.container-aboutTo p{
    text-align:center;
    margin: 15px 30px;
}

.container-therapies{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #6aa9e9;
    border-radius: 10px;
    color: white;
}

.container-therapies h2{
    margin-top: 30px;
}

.logo2{
    width: 45%;
    height: 40%;
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.therapies li{
    text-align: left;
    line-height: 30px;
}

.container-galery{
    display: flex;
    flex-direction:column;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    color: #6aa9e9;
}


.container-galery h2{
    margin-top: 30px;
}

.container-galery img{
    border-radius: 20px;
    margin: 10px;
    width: 90%;
}

.container-footer{
    display: flex;
    flex-direction:column;
    align-items: center;
    text-align: center;
    background-color: #6aa9e9;
    border-radius: 10px;
    color: white;
  }

.container-footer h2 {
    font-size: 24px;
}

.container-footer img {
    width: 60%;
    height: auto;
    margin-bottom: 10px;
}

.contatos{
    margin-top: 10px;
}

.contatos p {
    margin: 5px;
}

.adress{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.adress-paragraph{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.adress p {
    margin: 2px;
}

.telefon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.telefon p {
    margin-left: 5px;
}
 
}


@media (min-width: 769px) and (max-width: 1200px) {

    h2 {
        font-size: 35px;
    }

    .container-section1{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 70vh;
    }
    
    .consultorioImg-section1{
        width: 50%;
        height: 90%;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 50px;
        border-radius: 10px;
    }
    
    .texto-section1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        transform: translate(-60px, 60px);
        background-color: #6aa9e9;
        font-size: 30px;
        font-weight: bold;
        color: white;
        width: 50%;
        height: 70%;
        border-radius: 15px;
    }
    
    .texto-section1 h2{
        margin: 10px 0;
    }
    
    .section2{
        display: flex;
        flex-direction:column;
        align-items: center;
        text-align: center;
        background-color: #6aa9e9;
        border-radius: 10px;
        color: white;
    }
    
    .section2 h2{
        margin-top: 40px;
    }
    
    .second-section2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .foto-perfil{
        width: 35%;
        height: 30%;
        border-radius: 15px;
        margin: 20px 0;
    }
    
    .about-me{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 85%;
        margin-top: 20px;
        font-size: 20px;
        line-height: 30px;
    }

    .about-me h3 {
        margin: 0;
    }

   .experienciaProfissional h3{
        font-size: 23.4px;
    }

    .experienciaProfissional ul{
        text-align: justify;
        list-style: none;
        padding: 0;
        margin: 30px 100px;
    }

    .experienciaProfissional li {
        margin-top: 10px;
        font-size: 20px;
    }
        
    .container-aboutTo{
        display: flex;
        flex-direction:column;
        justify-content: center;
        text-align: center;
        background-color: white;
        border-radius: 10px;
        color: #6aa9e9;
        margin-top: 10px;
        height: 80vh;
    }

   
    .foto-puzzle{
        width: 35%;
        height: 70%;
        border-radius: 15px;
    }
    
      .subcontainer-aboutTo{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-around;
        height: 75%;
    }

    .subcontainer-aboutTo-p{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 40%;
        font-size: 20px;
    }

    .container-therapies{
        display: flex;
        flex-direction:column;
        justify-content: center;
        text-align: center;
        background-color: #6aa9e9;
        border-radius: 10px;
        color: white;
        margin-top: 10px;
        height: 80vh;
    }

    .subcontainer-therapies{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        height: 75%;
    }

    .logo2{
        width: 35%;
        height: 60%;
        border-radius: 15px;
        margin-left: 30px;
        margin-right: 20px;
    }

    .therapies{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        text-align: left;
        line-height: 40px;
        height: 75%;
        font-size: 20px;
    }

    .therapies li{
        width: 80%;
    }

    .container-galery{
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        background-color: white;
        color: #6aa9e9;
    }
    
    .container-galery h2{
        margin-top: 30px;
    }
    
    .subcontainer-galery{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 90%;
    }
    .subcontainer-galery img{
        width: 35%;
        border-radius: 20px;
        margin: 10px;
    }

    .container-footer{
        display: flex;
        flex-direction:column;
        align-items: center;
        text-align: center;
        background-color: #6aa9e9;
        border-radius: 10px;
        color: white;

      }
    
    .subcontainer-footer{
        display: flex;
        flex-direction:row;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 20px;
        font-size: 20px;
    }
    
    .container-footer img {
        width: 25%;
        height: auto;
        margin-bottom: 10px;
    }
    
    .adress{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .adress-paragraph{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .adress p {
        margin: 2px;
    }
    
    .telefon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .telefon p {
        margin-left: 5px;
    }
  
}

@media (min-width: 1201px) {
    h2 {
        font-size: 35px;
    }

    .container-section1{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 70vh;
    }
    
    .consultorioImg-section1{
        width: 50%;
        height: 90%;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 50px;
        border-radius: 10px;
    }
    
    .texto-section1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        transform: translate(-60px, 60px);
        background-color: #6aa9e9;
        font-size: 30px;
        font-weight: bold;
        color: white;
        width: 50%;
        height: 70%;
        border-radius: 15px;
    }
    
    .texto-section1 h2{
        margin: 10px 0;
    }
    
    .section2{
        display: flex;
        flex-direction:column;
        align-items: center;
        text-align: center;
        background-color: #6aa9e9;
        border-radius: 10px;
        color: white;
    }
    
    .section2 h2{
        margin-top: 40px;
    }
    
    .second-section2{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 90%;
    }
    
    .foto-perfil{
        width: 35%;
        height: 35%;
        border-radius: 15px;
        margin: 20px 0 30px 5px;
    }
    
    .about-me{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40%;
        margin-top: 20px;
        font-size: 20px;
        line-height: 30px;
    }

    .about-me h3 {
        margin: 0;
    }

    
    .experienciaProfissional{
        width: 90%;
    }

    .experienciaProfissional h3{
        font-size: 23.4px;
    }

    .experienciaProfissional ul{
        text-align: justify;
        list-style: none;
        padding: 0;
        margin: 30px 100px;
    }

    .experienciaProfissional li {
        margin-top: 10px;
        font-size: 20px;
    }
        
    .container-aboutTo{
        display: flex;
        flex-direction:column;
        justify-content: center;
        text-align: center;
        background-color: white;
        border-radius: 10px;
        color: #6aa9e9;
        margin-top: 10px;
        height: 80vh;
    }

   
    .foto-puzzle{
        width: 35%;
        height: 70%;
        border-radius: 15px;
    }
    
      .subcontainer-aboutTo{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-around;
        height: 75%;
    }

    .subcontainer-aboutTo-p{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 40%;
        font-size: 20px;
    }

    .container-therapies{
        display: flex;
        flex-direction:column;
        justify-content: center;
        text-align: center;
        background-color: #6aa9e9;
        border-radius: 10px;
        color: white;
        margin-top: 10px;
        height: 80vh;
    }

    .subcontainer-therapies{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        height: 75%;
    }

    .logo2{
        width: 35%;
        height: 60%;
        border-radius: 15px;
        margin-left: 30px;
        margin-right: 20px;
    }

    .therapies{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        text-align: left;
        line-height: 40px;
        height: 75%;
        font-size: 20px;
    }

    .therapies li{
        width: 80%;
    }

    .container-galery{
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        background-color: white;
        color: #6aa9e9;
    }
    
    .container-galery h2{
        margin-top: 30px;
    }
    
    .subcontainer-galery{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 90%;
    }
    .subcontainer-galery img{
        width: 35%;
        border-radius: 20px;
        margin: 10px;
    }

    .container-footer{
        display: flex;
        flex-direction:column;
        align-items: center;
        text-align: center;
        background-color: #6aa9e9;
        border-radius: 10px;
        color: white;

      }
    
    .subcontainer-footer{
        display: flex;
        flex-direction:row;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 20px;
        font-size: 20px;
    }
    
    .container-footer img {
        width: 25%;
        height: auto;
        margin-bottom: 10px;
    }
    
    .adress{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .adress-paragraph{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .adress p {
        margin: 2px;
    }
    
    .telefon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .telefon p {
        margin-left: 5px;
    }
   
}